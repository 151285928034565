<template>
  <div>
    <LoadingMessage v-if="isLoading" :message="'Loading history...'"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error"></ErrorMessage>

    <div v-if="!isLoading && !error" class="card">
      <div class="d-flex align-items-center border-bottom p-3">
        <div class="btn-group btn-group-sm me-2">
          <a
            @click="changeDateRange(dateRange)"
            v-for="(dateRange, dateRangeIndex) in dateRanges"
            :key="`key-${dateRangeIndex}`"
            :class="dateRange === activeDateRange ? 'btn-primary' : 'btn-outline-primary'"
            class="btn text-nowrap"
          >{{ dateRange }} days</a>
        </div>

        <select
          v-model="activeViewMode"
          :disabled="isLoading"
          class="form-select form-select-sm w-auto me-2"
        >
          <option value="" disabled selected>Select one</option>
          <option value="chart-view">Chart view</option>
          <option value="table-view">Table view</option>
        </select>

        <div class="flex-grow-1"></div>

        <SubmitButton
          @click="fetchHistory"
          :text="'Refresh'"
          :isLoading="isLoading"
          :disabled="isLoading"
          class="btn btn-sm btn-primary"
        ></SubmitButton>
      </div>

      <div v-if="records.length">
        <div v-if="activeViewMode === 'chart-view'" class="m-3x">
          <div class="m-3 position-relative">
            <div class="d-flex align-content-center">
              <h5 class="flex-grow-1 m-0">Macro Distribution</h5>
              <NutritionLegend :legends="macrosLegends" class="ms-3"></NutritionLegend>
            </div>
            <NutritionMacrosGraph :chartData="macrosData" class="mt-3"></NutritionMacrosGraph>
          </div>
          <div class="m-3 position-relative">
            <h5 class="m-0">Total Calories</h5>
            <NutritionCaloriesGraph :chartData="caloriesData" class="mt-3"></NutritionCaloriesGraph>
          </div>
        </div>

        <NutritionMacrosTable
          v-if="activeViewMode === 'table-view'"
          :userId="userId"
          :records="records"
        ></NutritionMacrosTable>
      </div>

      <div v-else class="m-3">No data</div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';
import { chartColors, chartBarItemStyles, chartLineItemStyles } from '@/core/setting';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(isSameOrAfter);
dayjs.extend(objectSupport);

const timeArgs = { h: 0, m: 0, s: 0 };

export default {
  name: 'NutritionHistory',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    NutritionMacrosTable: () => import('@/components/nutrition/NutritionMacrosTable'),
    NutritionMacrosGraph: () => import('@/components/nutrition/NutritionMacrosGraph'),
    NutritionCaloriesGraph: () => import('@/components/nutrition/NutritionCaloriesGraph'),
    NutritionLegend: () => import('@/components/nutrition/NutritionLegend'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    startDate() {
      return this.today.subtract(this.activeDateRange, 'day');
    },
    macrosData() {
      const labels = [];
      const carb = [];
      const protein = [];
      const fat = [];

      this.records.forEach((record) => {
        const { dateString, dayMacros } = record;
        labels.push(this.$options.filters.dateReadableShortArray(dateString));
        if (dayMacros) {
          const { c, p, f } = dayMacros;
          carb.push(c.value);
          protein.push(p.value);
          fat.push(f.value);
        } else {
          carb.push(0);
          protein.push(0);
          fat.push(0);
        }
      });

      return {
        labels,
        datasets: [
          {
            ...chartBarItemStyles,
            label: 'Carb (g)',
            data: carb,
            borderColor: chartColors.carbDark,
            backgroundColor: chartColors.carb,
          },
          {
            ...chartBarItemStyles,
            label: 'Protein (g)',
            data: protein,
            borderColor: chartColors.proteinDark,
            backgroundColor: chartColors.protein,
          },
          {
            ...chartBarItemStyles,
            label: 'Fat (g)',
            data: fat,
            borderColor: chartColors.fatDark,
            backgroundColor: chartColors.fat,
          },
        ],
      };
    },
    caloriesData() {
      const labels = [];
      const calories = [];

      this.records.forEach((record) => {
        const { dateString, dayMacros } = record;
        labels.push(this.$options.filters.dateReadableShortArray(dateString));
        if (dayMacros) {
          const { total } = dayMacros;
          calories.push(total.value);
        } else {
          calories.push(0);
        }
      });

      return {
        labels,
        datasets: [{
          ...chartLineItemStyles,
          label: 'Total calories (cals)',
          data: calories,
        }],
      };
    },
  },
  methods: {
    async fetchHistory() {
      this.isLoading = true;
      this.error = null;
      this.records = [];
      try {
        const { userId, startDate, dateFormat } = this;
        const res = await httpGet(`/nutrition/${userId}/history/${startDate.format(dateFormat)}`);
        this.parseData(res.data);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    parseData(data) {
      if (!data.length) {
        this.records = [];
        return;
      }

      const records = [];
      for (let index = 0; index < this.activeDateRange; index += 1) {
        const day = this.startDate.add(index + 1, 'day');
        records.push({
          dateJs: day,
          dateString: day.format(this.dateFormat),
          day: day.format('dddd'),
          dayIndex: parseInt(day.format('d'), 10),
          dayPlan: null,
          dayMacros: null,
          nutrition: null,
        });
      }

      data.forEach((item) => {
        const itemDate = dayjs(item.createdAt);
        records.forEach((record, index) => {
          const { dateJs, dayIndex } = record;
          if (dateJs.isSameOrAfter(itemDate, 'day')) {
            const nutrition = JSON.parse(item.data);
            records[index].nutrition = nutrition;

            const {
              dailyPlan,
              maintenance,
              surplus,
              deficit,
            } = nutrition;

            const dayPlan = dayIndex === 0
              ? dailyPlan[dailyPlan.length - 1]
              : dailyPlan[dayIndex - 1];
            records[index].dayPlan = dayPlan;

            if (dayPlan > 0) {
              records[index].dayMacros = surplus;
            } else if (dayPlan < 0) {
              records[index].dayMacros = deficit;
            } else {
              records[index].dayMacros = maintenance;
            }
          }
        });
      });

      this.records = records;
    },
    changeDateRange(dateRange) {
      this.activeDateRange = dateRange;
      this.fetchHistory();
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      records: [],
      activeViewMode: 'chart-view',
      macrosLegends: [
        {
          label: 'Carb (g)',
          color: chartColors.carb,
        },
        {
          label: 'Protein (g)',
          color: chartColors.protein,
        },
        {
          label: 'Fat (g)',
          color: chartColors.fat,
        },
      ],
      today: dayjs(timeArgs),
      dateFormat: 'YYYY-MM-DD',
      dateRanges: [30, 60, 90],
      activeDateRange: 60,
    };
  },
  mounted() {
    this.fetchHistory();
  },
};
</script>
